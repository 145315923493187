// @flow

import styled from '@emotion/styled'

export const Container = styled.div`
  position: relative;
`

export const Background = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none;
`

export const ContentContainer = styled.div`
  position: relative;
  z-index: 2;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`

export const Device = styled.div`
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none;
`
