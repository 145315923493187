// @flow
import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import YouTubePlayer from 'react-player/lib/players/YouTube'
// import Helmet from 'react-helmet'
import Slick from 'react-slick'
import slugify from 'slugify'
import Analytics from '../utils/analytics'
import RenderIf from '../components/RenderIf'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Nav from '../components/Nav'
import Icon from '../components/Icon'
import PaddedContent from '../components/PaddedContent'
import NewsletterSignUp from '../components/NewsletterSignUp'
import ContactFormWithEmailLink from '../components/ContactFormWithEmailLink'
import Lightbox from '../components/Lightbox'
import FeatureBelt from '../components/FeatureBelt'
import FeatureBelt2 from '../components/FeatureBelt2'
import Button from '../components/Button'
import Devices from '../components/Devices'
import { type FeatureBeltT, type FeatureBelt2T, type FluidWithWebp } from '../types'
import { Header, VideoContainer, FeatureList } from '../styles/pages/index'

type Props = {
  location: any,
}

type State = {
  videoLightboxIsOpen: boolean,
}

type StaticQueryData = {
  contentfulHomePage: {|
    pageTitle: string,
    keywords: Array<string>,
    description: {
      description: string,
    },
    headingTitle: string,
    headingSubtitle: string,
    headingLargeImages: Array<{
      id: string,
      title: string,
      ...FluidWithWebp,
    }>,
    headingSmallImages: Array<{
      id: string,
      title: string,
      ...FluidWithWebp,
    }>,
    videoTitle: string,
    videoDescription: {
      videoDescription: string,
    },
    videoThumbnail: {
      title: string,
      ...FluidWithWebp,
    },
    videoURL: string,
    featureBelt1: FeatureBeltT,
    featureListTitle: string,
    featureListItems: Array<{
      id: string,
      title: string,
      shortDescription: string,
      icon: {
        title: string,
        file: {
          url: string,
        },
      },
    }>,
    featureListButtonLabel: string,
    featureListButtonURL: string,
    featureBelt2: FeatureBeltT,
    featureBelt3: FeatureBelt2T,
    contactFormTitle: string,
    contactFormSubtitle: string,
    contactFormButtonLabel: string,
  |},
}

class IndexPage extends React.Component<Props, State> {
  state = {
    videoLightboxIsOpen: false,
  }

  videoPlayer: { getCurrentTime: () => number } | null

  componentDidMount() {
    Analytics.pageview()
  }

  setVideoPlayerRef = (element: { getCurrentTime: () => number } | null) => {
    this.videoPlayer = element
  }

  handleVideoThumbnailClick = () => {
    this.setState({
      videoLightboxIsOpen: true,
    })
  }

  handleVideoLightboxClose = () => {
    Analytics.event({
      category: 'Video',
      action: 'Closed',
      value: Math.round(
        this.videoPlayer && this.videoPlayer ? this.videoPlayer.getCurrentTime() : -1,
      ),
    })

    this.setState({ videoLightboxIsOpen: false })
  }

  render() {
    const { location } = this.props
    const { videoLightboxIsOpen } = this.state

    return (
      <StaticQuery
        query={homePageQuery}
        render={(data: StaticQueryData) => {
          const {
            // pageTitle,
            keywords,
            description: { description },
            headingTitle,
            headingSubtitle,
            headingLargeImages,
            headingSmallImages,
            videoTitle,
            videoDescription: { videoDescription },
            videoThumbnail,
            videoURL,
            featureBelt1,
            featureListTitle,
            featureListItems,
            featureListButtonLabel,
            featureListButtonURL,
            featureBelt2,
            featureBelt3,
            contactFormTitle,
            contactFormSubtitle,
            contactFormButtonLabel,
          } = data.contentfulHomePage

          return (
            <Layout dependencies={['slick']}>
              <SEO title="Home" description={description} keywords={keywords} location={location} />
              <Header>
                <Header.Background />
                <Nav location={location} />
                <PaddedContent>
                  <Header.Title>{headingTitle}</Header.Title>
                  <Header.Subtitle>{headingSubtitle}</Header.Subtitle>
                </PaddedContent>
                <RenderIf devices={['tablet', 'desktop', 'desktopHD']}>
                  <Header.ImageContainer>
                    <Devices.IpadAngleBackLandscape>
                      <Slick
                        dots={false}
                        accessibility={false}
                        autoplay
                        autoplaySpeed={6000}
                        speed={3000}
                        arrows={false}
                        fade
                        pauseOnHover={false}
                      >
                        {headingLargeImages.map(image => (
                          <Img key={image.id} fluid={image.fluid} alt={image.title} />
                        ))}
                      </Slick>
                    </Devices.IpadAngleBackLandscape>
                  </Header.ImageContainer>
                </RenderIf>
                <RenderIf devices={['phone']}>
                  <Header.SmallImageContainer>
                    <Devices.IpadHeadOnLandscape>
                      <Slick
                        dots={false}
                        accessibility={false}
                        autoplay
                        autoplaySpeed={6000}
                        arrows={false}
                        speed={1000}
                        // fade
                        pauseOnHover={false}
                      >
                        {headingSmallImages.map(image => (
                          <Img key={image.id} fluid={image.fluid} alt={image.title} />
                        ))}
                      </Slick>
                    </Devices.IpadHeadOnLandscape>
                  </Header.SmallImageContainer>
                </RenderIf>
              </Header>
              <PaddedContent>
                <VideoContainer>
                  <div id="meet-goji">
                    <VideoContainer.Title>{videoTitle}</VideoContainer.Title>
                    <VideoContainer.Description>{videoDescription}</VideoContainer.Description>
                  </div>
                  <div>
                    <VideoContainer.ThumnbnailContainer onClick={this.handleVideoThumbnailClick}>
                      <Img fluid={videoThumbnail.fluid} alt={videoThumbnail.title} />
                      <VideoContainer.Overlay />
                      <VideoContainer.IconContainer>
                        <Icon type="play" />
                      </VideoContainer.IconContainer>
                    </VideoContainer.ThumnbnailContainer>
                    <Lightbox
                      isOpen={videoLightboxIsOpen}
                      content={
                        <YouTubePlayer
                          ref={this.setVideoPlayerRef}
                          url={videoURL}
                          controls
                          light
                          playing
                          // width="100%"
                          // height="100%"
                          config={{ youtube: { playerVars: { modestbranding: 0 } } }}
                          onPlay={() => {
                            Analytics.event({
                              category: 'Video',
                              action: 'Started or Resumed',
                            })
                          }}
                          onPause={() => {
                            Analytics.event({
                              category: 'Video',
                              action: 'Paused',
                              value: Math.round(
                                this.videoPlayer && this.videoPlayer
                                  ? this.videoPlayer.getCurrentTime()
                                  : -1,
                              ),
                            })
                          }}
                          onEnded={() => {
                            Analytics.event({
                              category: 'Video',
                              action: 'Finished',
                            })
                          }}
                        />
                      }
                      onClose={this.handleVideoLightboxClose}
                    />
                  </div>
                </VideoContainer>
                <NewsletterSignUp />
              </PaddedContent>
              <FeatureBelt data={featureBelt1} />
              <PaddedContent>
                <FeatureList>
                  <FeatureList.Title>{featureListTitle}</FeatureList.Title>
                  <FeatureList.Grid>
                    {featureListItems.map(item => (
                      <FeatureList.Item
                        key={item.id}
                        to={`/features#${slugify(item.title, { lower: true })}`}
                        analytics={`Home: Feature List: ${item.title}`}
                      >
                        <FeatureList.Item.Icon src={item.icon.file.url} alt={item.icon.title} />
                        <FeatureList.Item.Title>{item.title}</FeatureList.Item.Title>
                        <FeatureList.Item.Description>
                          {item.shortDescription}
                        </FeatureList.Item.Description>
                      </FeatureList.Item>
                    ))}
                  </FeatureList.Grid>
                  <FeatureList.Button>
                    <Button
                      label={featureListButtonLabel}
                      to={featureListButtonURL}
                      style={{ flex: 1 }}
                      analytics={`Home: Feature List: ${featureListButtonLabel}`}
                    />
                  </FeatureList.Button>
                </FeatureList>
              </PaddedContent>
              <FeatureBelt data={featureBelt2} tabletContentBackground="white" />
              <FeatureBelt2 data={featureBelt3} />
              <ContactFormWithEmailLink
                title={contactFormTitle}
                subtitle={contactFormSubtitle}
                buttonLabel={contactFormButtonLabel}
              />
            </Layout>
          )
        }}
      />
    )
  }
}

export default IndexPage

const homePageQuery = graphql`
  query HomePageQuery {
    contentfulHomePage {
      pageTitle
      keywords
      description {
        description
      }
      headingTitle
      headingSubtitle
      headingLargeImages {
        id
        title
        fluid(maxWidth: 1353, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      headingSmallImages {
        id
        title
        fluid(maxWidth: 1353, quality: 70) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      videoTitle
      videoDescription {
        videoDescription
      }
      videoThumbnail {
        title
        fluid(maxWidth: 304, quality: 70) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      videoURL
      featureBelt1 {
        layout
        title
        description {
          description
        }
        linkLabel
        linkURL
        backgroundImage {
          title
          fixed(width: 1440, quality: 100) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
      featureListTitle
      featureListItems {
        id
        title
        shortDescription
        icon {
          title
          file {
            url
          }
        }
      }
      featureListButtonLabel
      featureListButtonURL
      featureBelt2 {
        layout
        title
        description {
          description
        }
        linkLabel
        linkURL
        backgroundImage {
          title
          fixed(width: 1440, quality: 98) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
      featureBelt3 {
        title
        description {
          description
        }
        linkLabel
        linkURL
        image {
          title
          fluid(maxWidth: 760, quality: 98) {
            ...GatsbyContentfulFluid
          }
        }
      }
      contactFormTitle
      contactFormSubtitle
      contactFormButtonLabel
    }
  }
`
