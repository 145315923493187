// @flow

import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Link from '../Link'
import { media } from '../../styles/dimensions'
import Colors from '../../styles/colors'
import * as Fonts from '../../styles/fonts'

export const Container = styled.div`
  display: none;

  ${media.tablet`
    display: block;
    margin: 0 auto;
    width: 100%;
    overflow: hidden;
  `};

  ${media.desktop`
    display: block;
    position: relative;
    height: 699px;
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
    overflow: hidden;
  `};
`

const getBackgroundImagePositionFromProps = ({ layout }) => {
  if (layout === 'image-on-left') {
    return css`
      left: 0;
    `
  }

  return css`
    right: 0;
  `
}

export const BackgroundImage = styled.div`
  position: relative;

  ${media.tablet`
    height: 669px;
  `};

  ${props => css`
    .gatsby-image-wrapper {
      position: absolute !important;
      ${getBackgroundImagePositionFromProps(props)};
    }
  `}

  ${media.desktop`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  `};
`

const getContentPositionFromProps = ({ layout }) => {
  if (layout === 'image-on-left') {
    return css`
      right: 144px;
    `
  }

  return css`
    left: 144px;
  `
}

export const Content = styled.div`
  position: relative;
  text-align: center;

  ${props => media.tablet`
    background: ${props.tabletContentBackground === 'grey' ? Colors.offWhite : Colors.white};
    padding: 72px 112px;
  `};

  ${props => media.desktop`
    box-sizing: border-box;
    width: 555px;
    position: absolute;
    top: 72px;
    bottom: 72px;
    ${getContentPositionFromProps(props)};
    background: ${Colors.white};
    border-radius: 6px;
    box-shadow: 24px 27px 35px 0 ${Colors.util.fade({ color: Colors.pureBlack, amount: 0.92 })};
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    padding-bottom: 80px;
  `};
`

export const Title = styled.h2`
  ${Fonts.Title};

  ${media.tablet`
    /* padding: 72px 72px 0; */
    margin: 0 0 16px;
  `};

  ${media.desktop`
    padding: 112px 96px 0;
    margin: 0 0 16px;
  `};
`

export const Description = styled.p`
  ${Fonts.Subtitle};

  ${media.tablet`
    padding: 0 96px;
    margin-bottom: 40px;
  `};

  ${media.desktop`
    padding: 0 72px;
  `};
`

export const LinkButton = styled(Link)`
  ${Fonts.Link};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

export const ChevronIcon = styled.div`
  width: 24px;
  height: 24px;
  opacity: 0.8;
`
