// @flow

import styled from '@emotion/styled'
import Link from '../Link'
import { media } from '../../styles/dimensions'
import Colors from '../../styles/colors'
import * as Fonts from '../../styles/fonts'

export const Container = styled.div`
  background: ${Colors.grey5};

  ${media.tablet`
    display: flex;
    flex-direction: row;
    max-width: 1440px;
    margin: 0 auto;
    padding: 72px 0;
  `};
`

export const Image = styled.div`
  flex: 1;
  order: 0;
`

export const Content = styled.div`
  flex: 1;
  box-sizing: border-box;
  order: 1;
  padding: 40px 24px 24px;

  ${media.tablet`
    padding: 72px 24px;
  `};

  ${media.desktop`
    padding: 40px 24px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  `};
`

export const Title = styled.h2`
  ${Fonts.Title2};
  margin: 0 0 16px;

  ${media.desktop`
    padding-right: 230px;
  `};
`

export const Description = styled.p`
  ${Fonts.Body2};
  margin-bottom: 24px;

  ${media.desktop`
    padding-right: 230px;
  `};
`

export const LinkButton = styled(Link)`
  ${Fonts.Link};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

export const ChevronIcon = styled.div`
  width: 24px;
  height: 24px;
  opacity: 0.8;
`
