// @flow
import React from 'react'
import Img from 'gatsby-image'
import Icon from '../Icon'
import { type FeatureBeltT } from '../../types'
import {
  Container,
  BackgroundImage,
  Content,
  Title,
  Description,
  LinkButton,
  ChevronIcon,
} from './style'

type Props = {
  data: FeatureBeltT,
  tabletContentBackground?: 'white' | 'grey',
}

const FeatureBelt = ({ data, tabletContentBackground }: Props) => (
  <Container>
    <BackgroundImage layout={data.layout}>
      <Img fixed={data.backgroundImage.fixed} alt={data.backgroundImage.title} />
    </BackgroundImage>
    <Content layout={data.layout} tabletContentBackground={tabletContentBackground}>
      <div>
        <Title>{data.title}</Title>
        <Description>{data.description.description}</Description>
      </div>
      <div>
        <LinkButton to={data.linkURL} analytics={`FeatureBelt: ${data.title} - ${data.linkLabel}`}>
          {data.linkLabel}
          <ChevronIcon>
            <Icon type="right-chevron" />
          </ChevronIcon>
        </LinkButton>
      </div>
    </Content>
  </Container>
)

FeatureBelt.defaultProps = {
  tabletContentBackground: 'grey',
}

export default FeatureBelt
