// @flow

import styled from '@emotion/styled'
import Colors from '../../../styles/colors'

export const Container = styled.div`
  position: relative;
`

const deviceWidth = 1772
const deviceHeight = 1386

export const ContentContainer = styled.div`
  padding-top: ${(73 / deviceHeight) * 100}%;
  padding-right: ${(110 / deviceWidth) * 100}%;
  padding-bottom: ${(140 / deviceHeight) * 100}%;
  padding-left: ${(115 / deviceWidth) * 100}%;
  position: relative;
`

ContentContainer.Background = styled.div`
  position: absolute;
  top: ${(73 / deviceHeight) * 100}%;
  right: ${(110 / deviceWidth) * 100}%;
  bottom: ${(170 / deviceHeight) * 100}%;
  left: ${(115 / deviceWidth) * 100}%;
  background: ${Colors.pureBlack};
`

export const Device = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none;
`
