// @flow
import * as React from 'react'
import Icon from '../Icon'
import { Container, Backdrop, Modal, CloseIcon, ModalContent } from './style'

type Props = {
  isOpen: boolean,
  onClose: Function,
  content: React.Node,
  forceFullWidth?: boolean,
}

const Lightbox = ({ isOpen, onClose, content, forceFullWidth }: Props) => {
  if (!isOpen) {
    // $FlowFixMe
    if (typeof window !== 'undefined') {
      window.document.querySelector('body').classList.remove('modal-open')
      return null
    }

    return null
  }

  if (typeof window !== 'undefined') {
    // $FlowFixMe
    window.document.querySelector('body').classList.add('modal-open')
  }

  return (
    <Container>
      <Backdrop />
      <Modal forceFullWidth={forceFullWidth}>
        <CloseIcon onClick={onClose}>
          <Icon type="close" />
        </CloseIcon>
        <ModalContent>{content}</ModalContent>
      </Modal>
    </Container>
  )
}

Lightbox.defaultProps = {
  forceFullWidth: false,
}

export default Lightbox
