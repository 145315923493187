// @flow
import React from 'react'
import Img from 'gatsby-image'
import Icon from '../Icon'
import { type FeatureBelt2T } from '../../types'
import { Container, Image, Content, Title, Description, LinkButton, ChevronIcon } from './style'

type Props = FeatureBelt2T

const FeatureBelt2 = ({ data }: { data: Props }) => (
  <Container>
    <Content>
      <Title>{data.title}</Title>
      <Description>{data.description.description}</Description>
      <LinkButton to={data.linkURL} analytics={`FeatureBelt: ${data.title} - ${data.linkLabel}`}>
        {data.linkLabel}
        <ChevronIcon>
          <Icon type="right-chevron" />
        </ChevronIcon>
      </LinkButton>
    </Content>
    <Image>
      <Img fluid={data.image.fluid} alt={data.image.title} />
    </Image>
  </Container>
)

export default FeatureBelt2
