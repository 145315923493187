// @flow

import styled from '@emotion/styled'
import { media } from '../../styles/dimensions'
import Colors from '../../styles/colors'

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
`

export const Backdrop = styled.div`
  position: fixed;
  z-index: 100;
  background: ${Colors.util.fade({ color: Colors.pureBlack, amount: 0.1 })};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  cursor: default;
`

export const Modal = styled.div`
  top: 48px;
  /* right: 16px; */
  /* bottom: 16px; */
  /* left: 16px; */
  /* position: absolute; */
  position: relative;
  z-index: 101;
  margin: 24px auto;
  max-width: 80%;

  max-height: 100%;

  /* height: ${props => (props.forceFullWidth ? '100%' : 'auto')}; */
  /* width: ${props => (props.forceFullWidth ? '100%' : 'auto')}; */

  ${props => media.tablet`
    right: ${props.forceFullWidth ? '16px' : 'auto'};
    /* bottom: auto; */
    left: ${props.forceFullWidth ? '16px' : 'auto'};
  `};
`

export const CloseIcon = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: -32px;
  right: 0;
  color: ${Colors.white};
  cursor: pointer;
`

export const ModalContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  max-height: 100% !important;
  max-width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.pureBlack};
  border-radius: 8px;
  /* overflow: hidden; */
  padding: 24px;
  overflow: hidden;

  .gatsby-image-wrapper {
    /* max-width: 80%; */
    margin: 0 auto;
  }

  img {
    /* flex: 1; */
    /* display: block;
    margin: 0 auto; */
    margin: 0 auto;
    /* max-width: 80%; */
    /* height: 100% !important; */
    /* width: 100% !important; */
  }
`
