// @flow

import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { type FluidWithWebp } from '../../../types'
import { Container, ContentContainer, Background, Device } from './style'

type Props = {
  children: React.Node,
}

type StaticQueryData = {
  device: {
    image: FluidWithWebp,
  },
  background: {
    image: FluidWithWebp,
  },
}

const IPadAngleBackLandscape = ({ children }: Props) => (
  <StaticQuery
    query={imageQuery}
    render={(data: StaticQueryData) => (
      <Container>
        <Background>
          <Img fluid={data.background.image.fluid} alt="" />
        </Background>
        <ContentContainer>{children}</ContentContainer>
        <Device>
          <Img fluid={data.device.image.fluid} alt="" />
        </Device>
      </Container>
    )}
  />
)

const imageQuery = graphql`
  query iPadAngleBackImageQuery {
    device: file(
      relativePath: {
        eq: "components/Devices/IPadAngleBackLandscape/assets/ipad-angle-back-landscape-masked.png"
      }
    ) {
      image: childImageSharp {
        fluid(maxWidth: 1353, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    background: file(
      relativePath: {
        eq: "components/Devices/IPadAngleBackLandscape/assets/ipad-angle-back-landscape-background.png"
      }
    ) {
      image: childImageSharp {
        fluid(maxWidth: 1353, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

export default IPadAngleBackLandscape
