// @flow

import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { type FluidWithWebp } from '../../../types'
import { Container, ContentContainer, Device } from './style'

type Props = {
  children: React.Node,
}

type StaticQueryData = {
  file: {
    image: FluidWithWebp,
  },
}

const IPadHeadOnLandscape = ({ children }: Props) => (
  <StaticQuery
    query={imageQuery}
    render={(data: StaticQueryData) => (
      <Container>
        <ContentContainer>
          <ContentContainer.Background />
          {children}
        </ContentContainer>
        <Device>
          <Img fluid={data.file.image.fluid} alt="" />
        </Device>
      </Container>
    )}
  />
)

const imageQuery = graphql`
  query iPadHeadOnImageQuery {
    file(
      relativePath: {
        eq: "components/Devices/IPadHeadOnLandscape/assets/ipad-head-on-landscape-masked.png"
      }
    ) {
      image: childImageSharp {
        fluid(maxWidth: 1000, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default IPadHeadOnLandscape
