import styled from '@emotion/styled'
import Link from '../../components/Link'
import { media } from '../dimensions'
import * as Fonts from '../fonts'
import Colors from '../colors'
import { ButtonContainer, LinkContainer } from '../../components/Button/style'

export const Header = styled.div`
  position: relative;
  margin-bottom: 16px;
`

Header.Background = styled.div`
  background: ${Colors.secondary};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 20%;
  left: 0;
`

Header.Title = styled.h1`
  ${Fonts.Title};
  position: relative;
  margin: 0 0 24px;
  color: ${Colors.white};
  text-align: center;
`

Header.Subtitle = styled.h2`
  ${Fonts.Subtitle};
  text-align: center;
  color: ${Colors.white};
  max-width: 360px;
  margin: 0 auto 48px;

  ${media.tablet`
    margin: 0 auto 72px;
  `};
`

/*
  Header Image Mobile:
    1538 x 1152
*/

/*
  Header Image Tablet+:
    2388 x 1668
*/

Header.ImageContainer = styled.div`
  max-width: 1353px;
  margin: 0 auto;
  padding: 0 16px;

  ${media.tablet`
    padding: 0 40px;
  `};
`

Header.SmallImageContainer = styled.div`
  /* padding: 0 16px; */
`

export const VideoContainer = styled.div`
  margin: 40px auto;

  ${media.tablet`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 48px 0;

    > div:first-of-type {
      padding-right: 10%;
      flex: 1;
    }

    > div:last-of-type {
      flex: 1;
      max-width: 530px;
    }
  `};
`

VideoContainer.Title = styled.h2`
  ${Fonts.Title};
  margin: 0 0 24px;
`

VideoContainer.Description = styled.p`
  ${Fonts.Subtitle};
  margin: 0 0 24px;
`

VideoContainer.ThumnbnailContainer = styled.a`
  cursor: pointer;
  display: block;
  margin: 0 auto;
  max-width: 530px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;

  img {
    transition: transform 2s !important;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
`

VideoContainer.Overlay = styled.div`
  position: absolute;
  opacity: 0.5;
  background: ${Colors.grey3};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 6px;
`

VideoContainer.IconContainer = styled.div`
  width: 80px;
  height: 80px;
  position: absolute;
  color: ${Colors.white};
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  opacity: 0.6;
  transition: transform 0.25s;

  /* stylelint-disable */
  ${VideoContainer.ThumnbnailContainer}:hover & {
    transform: scale(1.1);
  }
  /* stylelint-enable */
`

export const FeatureList = styled.div`
  margin: 24px 0;

  ${media.tablet`
    margin: 120px 0 72px;
  `};
`

FeatureList.Title = styled.h2`
  ${Fonts.Title};
  text-align: center;
  margin-bottom: 64px;
`

FeatureList.Grid = styled.div`
  ${media.tablet`
    display: grid;
    grid-template-columns: 1fr 1fr;
  `};

  ${media.desktop`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `};
`

FeatureList.Item = styled(Link)`
  text-align: center;
  margin-bottom: 24px;
  display: block;
  border-radius: 6px;
  padding: 16px;
  text-decoration: none;

  ${media.tablet`
    margin-bottom: 56px;
  `};

  &:hover {
    background: ${Colors.grey5};

    img {
      transform: scale(1.1);
    }
  }
`

FeatureList.Item.Icon = styled.img`
  margin-bottom: 16px;
  transition: transform 0.25s;

  ${media.tablet`
    margin-bottom: 32px;
  `};
`

FeatureList.Item.Title = styled.h3`
  ${Fonts.Subtitle2};
  margin-bottom: 8px;
`

FeatureList.Item.Description = styled.p`
  ${Fonts.Body2};
`

FeatureList.Button = styled.div`
  text-align: center;
  display: flex;

  ${media.tablet`
    display: block;
    
    ${ButtonContainer},
    ${LinkContainer} {
      display: inline-block;
    }
  `};
`
